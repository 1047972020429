'use client';

import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as React from 'react';
import { navigationStateAtom } from '@/hooks/useCollapseMenu';
import { useAtomValue } from 'jotai';
import { cn } from '../../utils';
const TooltipProvider = TooltipPrimitive.Provider;
TooltipProvider.displayName = 'TooltipProvider';
const Tooltip = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;
const TooltipPortal = TooltipPrimitive.Portal;
const TooltipContent = React.forwardRef<React.ElementRef<typeof TooltipPrimitive.Content>, React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>>(({
  className,
  sideOffset = 8,
  ...props
}, ref) => <TooltipPrimitive.Content ref={ref} sideOffset={sideOffset} className={cn('z-50 overflow-hidden rounded-md border border-gray-100 bg-white px-3 py-2 text-xs text-gray-700 font-bold shadow-md animate-in fade-in-50 data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1', className)} {...props} />);
TooltipContent.displayName = TooltipPrimitive.Content.displayName;
const TooltipWithOverflow = ({
  title,
  className,
  tooltipContentClassName
}: {
  title?: string | null;
  className?: string;
  tooltipContentClassName?: string;
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);
  const menuState = useAtomValue(navigationStateAtom);
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    const checkOverflow = () => {
      if (!containerRef.current) {
        return;
      }
      setOpen(false);
      setIsOverflowing(containerRef.current.scrollWidth > containerRef.current.clientWidth);
    };
    checkOverflow();

    // Check overflow after animation is finished
    setTimeout(() => {
      checkOverflow();
    }, 500);
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [containerRef, title, menuState]);
  return <Tooltip open={open && isOverflowing} onOpenChange={setOpen} data-sentry-element="Tooltip" data-sentry-component="TooltipWithOverflow" data-sentry-source-file="index.tsx">
      <TooltipTrigger asChild data-sentry-element="TooltipTrigger" data-sentry-source-file="index.tsx">
        <span ref={containerRef} className={cn(className, 'truncate inline-block max-w-full')}>
          {title}
        </span>
      </TooltipTrigger>
      <TooltipContent className={cn('max-w-[250px]', tooltipContentClassName)} data-sentry-element="TooltipContent" data-sentry-source-file="index.tsx">
        <div>{title}</div>
      </TooltipContent>
    </Tooltip>;
};
const TooltipWithNonCloseByClick = ({
  className,
  asChild,
  triggerNode,
  children
}: {
  className?: string;
  asChild: boolean;
  triggerNode: React.ReactNode;
  children: React.ReactNode;
}) => {
  const triggerRef = React.useRef<any>(null);
  const onTriggerClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    e.preventDefault();
  };
  return <Tooltip data-sentry-element="Tooltip" data-sentry-component="TooltipWithNonCloseByClick" data-sentry-source-file="index.tsx">
      <TooltipTrigger asChild={asChild} ref={!asChild ? triggerRef : null} onClick={!asChild ? onTriggerClick : undefined} data-sentry-element="TooltipTrigger" data-sentry-source-file="index.tsx">
        {asChild ? <div className="cursor-pointer" onClick={onTriggerClick} ref={triggerRef}>
            <div className="pointer-events-none">{triggerNode}</div>
          </div> : triggerNode}
      </TooltipTrigger>
      <TooltipContent onPointerDownOutside={event => {
      if (event.target === triggerRef.current) {
        event.preventDefault();
      }
    }} className={className} data-sentry-element="TooltipContent" data-sentry-source-file="index.tsx">
        {children}
      </TooltipContent>
    </Tooltip>;
};
export { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, TooltipWithOverflow, TooltipWithNonCloseByClick };